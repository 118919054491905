import { useNavigate } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import axios from 'axios';
import { Box, Grid, Button, Typography, Snackbar, Slide, SlideProps, SnackbarContent, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, FormGroup, Drawer, Checkbox, Stack, TextField, InputAdornment, Tooltip } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextFieldsInput from "../commons/text-fields-input";
import BarcodeIcon from '../../icons/barcodeIcon.svg';
import SuccessIcon from '../../icons/successIcon.svg';
import CloseIcon from '../../icons/closeIcon.svg';
import UploadIcon from '../../icons/uploadIcon.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '../../icons/SearchIcon.svg';
import './BarcodeListEmp.module.css';
import qs from 'qs';

const BarcodeListEmp = () => {
    const router = useNavigate();
    const [requestSnackbarOpen, setRequestSnackbarOpen] = useState(false);
    const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    // const [selectedSkuRange, setSelectedSkuRange] = useState<string>('');
    const [rows, setRows] = useState<BarcodeItem[]>([]);
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const [requestDrawerOpen, setRequestDrawerOpen] = useState(false);
    const initialFormValues = {
        brandName: '',
        productName: '',
        description: '',
        sku: ''
    };
    const [formValues, setFormValues] = useState(initialFormValues);
    const [brandData, setBrandData] = useState<DataOption[]>([]);
    const [productData, setProductData] = useState<DataOption[]>([]);
    const [filteredData, setFilteredData] = useState([]);
    const [showFilteredData, setShowFilteredData] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [formErrors, setFormErrors] = useState({
        brandName: '',
        productName: '',
        description: '',
        sku: ''
    });

    const [loading, setLoading] = useState(false);
    const [brandPage, setBrandPage] = useState(0);
    const [hasMoreBrandData, setHasMoreBrandData] = useState(true);

    interface BarcodeItem {
        sno: string;
        brandName: string;
        productName: string;
        description: string;
        sku: string;
        requestedDate: string;
        assignedDate: string;
        employeeName: string;
        employeeId: string;
        barcode: string;
        assignee: string;
    }

    interface DataOption {
        value: string;
        label: string;
    }

    const formatDate = (dateString: any) => {
        if (!dateString) {
            return '-';
        }
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const handleCloseRequestSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setRequestSnackbarOpen(false);
    };

    const handleOpenRequestSnackbar = (event: any) => {
        setRequestSnackbarOpen(true);
    };

    type Anchor = 'right';

    const SlideTransition = (props: SlideProps) => {
        return <Slide {...props} direction="left" />;
    };
    const handleBrandCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedBrands((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
    };

    const handleProductCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedProducts((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
    };

    const customMenuProps = {
        PaperProps: {
            style: {
                backgroundColor: '#f0f0f0',
                marginBlockStart: '1.5vh',
                boxShadow: 'none',
            },
        },
    };

    const handleFormInputChange = (name: string, value: string) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));

        setFormErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleBrandScroll = async (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        const buffer = 100;

        if (scrollHeight - scrollTop <= clientHeight + buffer && !loading && hasMoreBrandData) {
            setLoading(true);
            await getBrandData(brandPage + 1);
            setBrandPage(prev => prev + 1);
            setLoading(false);
        }
    };

    const handleRequestBarcode = () => {
        const token = sessionStorage.getItem('AccessToken');
        if (validateForm() && token) {
            empBarcodeRequest(formValues);
            setRequestDrawerOpen(false);
        }
    };

    const validateForm = () => {
        const errors = {
            brandName: '',
            productName: '',
            description: '',
            sku: ''
        };
        let isValid = true;

        if (!formValues.brandName.trim()) {
            errors.brandName = 'Brand name is required and cannot be only spaces';
            isValid = false;
        }

        if (!formValues.productName.trim()) {
            errors.productName = 'Product name is required and cannot be only spaces';
            isValid = false;
        }

        if (!formValues.description.trim()) {
            errors.description = 'Description is required and cannot be only spaces';
            isValid = false;
        }

        if (!formValues.sku.trim()) {
            errors.sku = 'SKU range is required and cannot be only spaces';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: 350, overflowY: 'scroll' }}
            role="presentation"
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1.5vh' }}>
                <Typography variant="h6">
                    Filters
                </Typography>
                <Box>
                    <span onClick={() => setFilterDrawerOpen(false)} style={{ color: 'red', cursor: 'pointer' }}>Close</span>
                    <img onClick={() => setFilterDrawerOpen(false)} src={CloseIcon} style={{ cursor: 'pointer' }} alt="close" />
                </Box>
            </Box>
            <Grid item xs={3} style={{ marginInline: '3vh' }}>
                {/* <TextField
                    id="fsrch"
                    name="fsrch"
                    placeholder="Search"
                    size="small"
                    style={{ backgroundColor: '#f0f0f0', borderRadius: '2vh' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <img src={SearchIcon} alt="search" style={{ marginInlineStart: '-1vh' }} />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                            },
                        }
                    }}
                    onChange={handleSearchChange}
                /> */}
            </Grid>
            <Accordion sx={{
                outline: 'none',
                boxShadow: 'none',
                marginBlockStart: '2vh'
            }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="body1">Brand</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBlockStart: '-1.9vh', marginBlockEnd: '-3vh' }}>
                    <Box
                        sx={{
                            maxHeight: '200px',
                            overflowY: 'auto',
                        }}
                        onScroll={handleBrandScroll}
                    >
                        <FormGroup>
                            {brandData.map((brand) => (
                                <FormControlLabel
                                    sx={{
                                        marginBlock: '-3.5px',
                                        whiteSpace: 'normal',       // Allows wrapping onto multiple lines
                                        wordBreak: 'break-word',    // Breaks words if they are too long
                                    }}
                                    key={brand.value}
                                    control={
                                        <Checkbox
                                            checked={selectedBrands.includes(brand.value)}
                                            onChange={handleBrandCheckboxChange}
                                            value={brand.value}
                                        />
                                    }
                                    label={brand.label}
                                />
                            ))}
                        </FormGroup>
                        {loading && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1vh' }}>
                                <Typography variant="body2">Loading more...</Typography>
                            </Box>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{
                outline: 'none',
                boxShadow: 'none',
            }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="body1">Product</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginBlockStart: '-1.9vh', marginBlockEnd: '-3vh' }}>
                    <Box
                        sx={{
                            maxHeight: '200px',
                            overflowY: 'auto',
                        }}
                    >
                        <FormGroup>
                            {productData.map(option => (
                                <FormControlLabel
                                    sx={{ marginBlock: '-7px' }}
                                    key={option.value}
                                    control={
                                        <Checkbox
                                            checked={selectedProducts.includes(option.value)}
                                            onChange={handleProductCheckboxChange}
                                            value={option.value}
                                        />
                                    }
                                    label={option.label}
                                />
                            ))}
                        </FormGroup>
                        {loading && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1vh' }}>
                                <Typography variant="body2">Loading more...</Typography>
                            </Box>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Stack spacing={2} direction="row" style={{ marginInlineStart: '25vh', marginBlockStart: '5vh' }}>
                <Button variant="outlined" sx={{ textTransform: 'none' }} onClick={clearFilters}>Clear</Button>
                <Button variant="contained" onClick={handleApplyClick} sx={{ textTransform: 'none' }}>Apply</Button>
            </Stack>
        </Box>
    );

    const request = (anchor: Anchor) => (
        <Box
            sx={{ width: 450, overflowY: 'scroll' }}
            role="presentation"
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1.5vh' }}>
                <Typography variant="h6">
                    Request Barcode
                </Typography>
                <Box>
                    <span onClick={() => setRequestDrawerOpen(false)} style={{ color: 'red', cursor: 'pointer' }}>Close</span>
                    <img onClick={() => setRequestDrawerOpen(false)} src={CloseIcon} style={{ cursor: 'pointer' }} alt="close" />
                </Box>
            </Box>
            <Grid sx={{ marginInline: '2vh' }}>
                <Box sx={{ marginBlockEnd: '2vh' }}>
                    <Typography style={{ marginInlineStart: "2vh" }} variant="body2">Brand </Typography>
                    <div style={{ marginInlineStart: '1.3vh', }}>
                        <input style={{
                            width: '100%',
                            borderRadius: '1vh',
                            border: 'none',
                            marginBlockStart: '2vh',
                            padding: '1.2vh',
                            background: '#f0f0f0',
                        }}
                            placeholder="Enter Brand" width="100%" onChange={(e) => handleFormInputChange('brandName', e.target.value)}
                            name="brandName" type="text" minLength={1} maxLength={20} value={formValues.brandName}
                        />
                        {formErrors.brandName && (
                            <Typography variant="body2" color="error" sx={{ marginBlockStart: '2px' }}>
                                {formErrors.brandName}
                            </Typography>
                        )}
                    </div>
                </Box>
                <Box sx={{ marginBlockEnd: '2vh' }}>
                    <Typography style={{ marginInlineStart: "2vh" }} variant="body2">Product </Typography>
                    <div style={{ marginInlineStart: '1.3vh', }}>
                        <input style={{
                            width: '100%',
                            borderRadius: '1vh',
                            border: 'none',
                            marginBlockStart: '2vh',
                            padding: '1.2vh',
                            background: '#f0f0f0',
                        }}
                            placeholder="Enter Product" width="100%" onChange={(e) => handleFormInputChange('productName', e.target.value)}
                            name="productName" type="text" minLength={1} maxLength={20} value={formValues.productName}
                        />
                        {formErrors.productName && (
                            <Typography variant="body2" color="error" sx={{ marginBlockStart: '2px' }}>
                                {formErrors.productName}
                            </Typography>
                        )}
                    </div>
                </Box>
                <Box sx={{ marginBlockEnd: '2vh' }}>
                    <Typography style={{ marginInlineStart: "2vh" }} variant="body2">Description </Typography>
                    <div style={{ marginInlineStart: '1.3vh', }}>
                        <input style={{
                            width: '100%',
                            borderRadius: '1vh',
                            border: 'none',
                            marginBlockStart: '2vh',
                            padding: '1.2vh',
                            background: '#f0f0f0',
                        }}
                            placeholder="Enter Description" width="100%" onChange={(e) => handleFormInputChange('description', e.target.value)}
                            name="description" type="text" minLength={1} maxLength={50} value={formValues.description}
                        />
                        {formErrors.description && (
                            <Typography variant="body2" color="error" sx={{ marginBlockStart: '2px' }}>
                                {formErrors.description}
                            </Typography>
                        )}
                    </div>
                </Box>
                <Box sx={{ marginBlockEnd: '2vh' }}>
                    <Typography style={{ marginInlineStart: "2vh" }} variant="body2">SKU Range</Typography>
                    <div style={{ marginInlineStart: '1.3vh', }}>
                        <input style={{
                            width: '100%',
                            borderRadius: '1vh',
                            border: 'none',
                            marginBlockStart: '2vh',
                            padding: '1.2vh',
                            background: '#f0f0f0',
                        }}
                            placeholder="Enter SKU" width="100%" onChange={(e) => handleFormInputChange('sku', e.target.value.trim())}
                            name="sku" type="text" value={formValues.sku}
                        />
                        {formErrors.sku && (
                            <Typography variant="body2" color="error" sx={{ marginBlockStart: '2px' }}>
                                {formErrors.sku}
                            </Typography>
                        )}
                    </div>
                </Box>
            </Grid>
            <Stack spacing={2} direction="row" style={{ marginInlineStart: '57%', marginBlockStart: '5vh' }}>
                <Button variant="outlined" sx={{ textTransform: 'none' }} onClick={handleRequestCancel}>Cancel</Button>
                <Button variant="contained" onClick={handleRequestBarcode} sx={{ textTransform: 'none' }}>Submit</Button>
            </Stack>
        </Box>
    );

    const resetFormValues = () => {
        setFormValues(initialFormValues);
        setFormErrors({
            brandName: '',
            productName: '',
            description: '',
            sku: ''
        });
    };

    const handleRequestCancel = () => {
        resetFormValues();
        setRequestDrawerOpen(false);
    };

    const snackbarRequestContentProps = {
        message: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <img src={SuccessIcon} alt="" />&nbsp;
                    <span style={{ marginLeft: '8px' }}>Request submitted successfully</span>
                </div>&nbsp;&nbsp;
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={CloseIcon} onClick={handleCloseRequestSnackbar} style={{ cursor: 'pointer' }} alt="" />
                </div>
            </div>
        ),
        sx: {
            backgroundColor: 'white',
            color: 'green',
            borderRadius: '8px',
            padding: '1.5vh'
        },
    };

    useEffect(() => {
        getBarcodeList();
    }, []);

    useEffect(() => {
        if (selectedBrands.length > 0) {
            getProductData(selectedBrands);
        } else {
            setProductData([]);
        }
    }, [selectedBrands]);

    useEffect(() => {
        getBrandData();
    }, []);

    const getBarcodeList = async () => {
        const token = sessionStorage.getItem('AccessToken');
        const userId = sessionStorage.getItem('empId');
        try {
            const barcodeList = await axios.get(`https://packaginglibrary-qa-backend.citpl.info/api/v1/barcodes/getBarcodeList?userId=${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setRows(barcodeList?.data);
        } catch (error) {
            console.error('Error fetching employee barcode list:', error);
        }
    };



    const empBarcodeRequest = async ({ brandName, productName, description, sku }: any) => {
        const employeeName = sessionStorage.getItem('fullName');
        const employeeId = sessionStorage.getItem('empId');
        const empNo = sessionStorage.getItem('empNo');
        const token = sessionStorage.getItem('AccessToken');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        if (token && token.length > 0) {
            try {
                await axios.post(`https://packaginglibrary-qa-backend.citpl.info/api/v1/barcodes/createRequest?brandName=${brandName}&productName=${productName}&description=${description}&sku=${sku}&employeeName=${employeeName}&employeeId=${employeeId}&empNo=${empNo}`, null, {
                    headers: headers
                });
                getBarcodeList();
                handleOpenRequestSnackbar(false);
                handleRequestCancel();
            } catch (error) {
                console.error('Error while sending request', error);
            }
        }
    };

    // const getBrandData = async () => {
    //     const token = sessionStorage.getItem('AccessToken');
    //     const userId = sessionStorage.getItem('empId');
    //     try {
    //         const brandData = await axios.get(`https://packaginglibrary-qa-backend.citpl.info/api/v1/barcodes/brandsOfBarcode?userId=${userId}`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 }
    //             }
    //         );
    //         const formattedData = brandData.data.map((item: string) => ({
    //             value: item,
    //             label: item
    //         }));
    //         setBrandData(formattedData);
    //     }
    //     catch (error) {
    //         console.error('Brand not available', error);
    //     }
    // };

    const getBrandData = async (page = 0) => {
        const token = sessionStorage.getItem('AccessToken');
        const userId = sessionStorage.getItem('empId');
        try {
            const brandData = await axios.get(`https://packaginglibrary-qa-backend.citpl.info/api/v1/barcodes/brandsOfBarcode`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: { page, userId },
                paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
            });

            const formattedData = brandData.data.map((item: string) => ({
                value: item,
                label: item
            }));

            if (formattedData.length === 0) {
                setHasMoreBrandData(false);
            } else {
                setBrandData(prev => [...prev, ...formattedData]); // Append new data for pagination
            }
        } catch (error) {
            console.error('Brand data not available:', error);
        }
    };


    const getProductData = async (brandNames: string[]) => {
        const token = sessionStorage.getItem('AccessToken');
        const userId = sessionStorage.getItem('empId');
        try {
            const responses = await Promise.all(
                brandNames.map((brandName) =>
                    axios.get(`https://packaginglibrary-qa-backend.citpl.info/api/v1/barcodes/productsOfBarcode?brandName=${brandName}&userId=${userId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                )
            );
            const formattedData = responses.flatMap(response =>
                response.data.map((item: string) => ({
                    value: item,
                    label: item
                }))
            );
            setProductData(formattedData);
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };

    const getFilteredData = async (brandNames: any, productNames: any) => {
        const token = sessionStorage.getItem('AccessToken');
        const userId = sessionStorage.getItem('empId');
        try {
            const filteredData = await axios.get('https://packaginglibrary-qa-backend.citpl.info/api/v1/barcodes/filterOfBarcode', {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    brandNames: brandNames.join(','),
                    productNames: productNames.join(','),
                    userId: userId
                }
            });
            setFilteredData(filteredData.data);
            setShowFilteredData(true);
            setFilterDrawerOpen(false);
            // console.log(filteredData.data);
        }
        catch (error) {
            console.error('err', error);
        }
    };

    const clearFilters = () => {
        setSelectedBrands([]);
        setSelectedProducts([]);
        setFilteredData([]);
        setShowFilteredData(false);
    };


    // const handleApplyClick = () => {
    //     const selectedBrand = selectedCheckboxes.find((item) =>
    //         brandData.some((brand) => brand.value === item)
    //     );
    //     const selectedProduct = selectedCheckboxes.find((item) =>
    //         productData.some((product) => product.value === item)
    //     );

    //     getFilteredData(selectedBrand, selectedProduct);
    // };

    const handleApplyClick = () => {
        if (selectedBrands.length > 0 || selectedProducts.length > 0) {
            getFilteredData(selectedBrands, selectedProducts);
        }
    };


    const getSearchData = async (searchText: any) => {
        const token = sessionStorage.getItem('AccessToken');
        const userId = sessionStorage.getItem('empId');
        setRows([]);
        try {
            const res = await axios.get(`https://packaginglibrary-qa-backend.citpl.info/api/v1/barcodes/searchByBrandAndProduct?searchText=${searchText}&userId=${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setRows(res.data);
        }
        catch (error) {
            console.error('err', error);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm.length == 0) {
                setRows([]);
            }
        }, 200);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm) {
                getSearchData(searchTerm);
            } else {
                getBarcodeList();
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);


    return (
        <Grid item xs={9} sx={{ marginTop: '80px' }}>
            <Box >
                <Box className="back-icon" sx={{ marginBlockEnd: '1vh', display: 'flex', alignItems: 'center' }} >
                    <img className="icon" onClick={() => router('/')} alt="back icon" style={{ marginLeft: '4vh', height: '24px', width: '24px', cursor: 'pointer' }} src="/assets/icon/back_arrow.svg" />
                    <Typography onClick={() => router('/')} sx={{ marginLeft: '0%', fontSize: '17px', cursor: 'pointer' }}>Back</Typography>
                </Box>
                <Grid container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', marginBlockEnd: '4vh' }}>
                    <Grid sx={{ marginLeft: '5vh' }} item xs={2}>
                        <Typography variant="h6">My Dashboard</Typography>
                    </Grid>
                    <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '3vh', marginInlineEnd: '5vh', alignItems: 'center' }} item xs={5.5}>
                        <Grid item xs={5}>
                            <TextField
                                id="fsrch"
                                name="fsrch"
                                placeholder="Search"
                                size="small"
                                style={{ backgroundColor: '#f0f0f0', borderRadius: '2vh' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={SearchIcon} alt="search" style={{ marginInlineStart: '-1vh' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                                onChange={handleSearchChange}
                            />
                        </Grid>
                        <img className="icon" onClick={() => setFilterDrawerOpen(true)} alt="back icon" style={{ marginLeft: '0vh', height: '24px', width: '24px', cursor: 'pointer' }} src="/assets/filterIcon.svg" />
                        <Button sx={{ borderRadius: '1vh', textTransform: 'none' }} variant="outlined" onClick={() => setRequestDrawerOpen(true)} > Request Barcode</Button>
                        <Button sx={{ borderRadius: '1vh', textTransform: 'none' }} variant="outlined" onClick={() => router('/barcodeEmployee')}> Barcode List</Button>
                    </Grid>
                </Grid>
                <TableContainer className="custom-scrollbar" component={Paper} style={{
                    marginInlineStart: '5vh', width: '95%', maxHeight: '420px', overflowY: 'scroll', overflowX: 'scroll'
                }}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableHead style={{ backgroundColor: '#f0f0f0' }}>
                            <TableRow style={{ position: 'sticky', top: 0, backgroundColor: '#f0f0f0', zIndex: 1 }}>
                                <TableCell sx={{ fontWeight: 'bold' }}>S.No</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Brand</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Product</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Requested Date</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Assigned Date</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>SKU</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Barcode</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Assignee</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(showFilteredData ? filteredData : rows).length > 0 ? (
                                (showFilteredData ? filteredData : rows).map((barcodeList, index) => (
                                    <TableRow key={barcodeList.sno}>
                                        <TableCell sx={{ padding: '3vh' }} align="right">{index + 1}</TableCell>
                                        <TableCell sx={{ padding: '3vh' }} component="th" scope="row">
                                            {barcodeList?.brandName}
                                        </TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{barcodeList.productName}</TableCell>
                                        <TableCell sx={{ maxWidth: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <Tooltip title={barcodeList.description} arrow placement="left-start">
                                                <span>{barcodeList.description}</span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{formatDate(barcodeList.requestedDate)}</TableCell>
                                        <TableCell>
                                            {barcodeList.assignedDate ?
                                                <span style={{
                                                    color: 'green',
                                                    backgroundColor: '#1C98191A', borderRadius: '5px',
                                                    padding: '4px'
                                                }}>{formatDate(barcodeList.assignedDate)}</span> :
                                                <span>-</span>
                                            }
                                        </TableCell>
                                        <TableCell sx={{ padding: '3vh' }}>{barcodeList.sku}</TableCell>
                                        <TableCell>
                                            {barcodeList.barcode === 'InProgress' ? (
                                                <span style={{
                                                    color: '#FFA928',
                                                    backgroundColor: '#FFA9281A',
                                                    borderRadius: '5px',
                                                    padding: '4px'
                                                }}>{barcodeList.barcode}</span>
                                            ) : (
                                                <>
                                                    <span>{barcodeList.barcode}</span>&nbsp;&nbsp;
                                                    <img src={BarcodeIcon} alt="barcode" style={{ cursor: 'pointer' }} />
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {barcodeList.assignee === 'Not Assigned' ? (
                                                <span style={{
                                                    color: '#FF3737',
                                                    backgroundColor: '#FF37371A',
                                                    borderRadius: '5px',
                                                    padding: '4px'
                                                }}>
                                                    {barcodeList.assignee}
                                                </span>
                                            ) : (
                                                <span>{barcodeList.assignee}</span>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={9} sx={{ textAlign: 'center', padding: '3vh' }}>
                                        No Data
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Snackbar open={requestSnackbarOpen} autoHideDuration={3000} onClose={handleCloseRequestSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} TransitionComponent={SlideTransition}>
                <SnackbarContent {...snackbarRequestContentProps} />
            </Snackbar>
            <Drawer
                anchor='right'
                open={filterDrawerOpen}
                onClose={() => setFilterDrawerOpen(false)}
            >
                {list('right')}
            </Drawer>
            <Drawer
                anchor='right'
                open={requestDrawerOpen}
                onClose={() => setRequestDrawerOpen(false)}
            >
                {request('right')}
            </Drawer>
        </Grid>
    );
};

export default BarcodeListEmp;

